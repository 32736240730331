var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-2",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"align-items-center"},[_c('h4',{staticClass:"mr-auto mb-0"},[_c('unicon',{attrs:{"name":"bookmark","width":"18","height":"18"}}),_vm._v(" معلومات حساب المستخدم ")],1)]),_c('b-card-body',[_c('b-card-text',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('EKInputText',{attrs:{"label":"الاسم الكامل","name":"fullName","value":_vm.customerDto.fullName,"disabled":""}}),_c('EKInputText',{attrs:{"label":"العنوان","name":"address","value":_vm.customerDto.address,"disabled":""}})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('EKInputText',{attrs:{"label":"البريد الإلكتروني","name":"email","value":_vm.customerDto.email,"disabled":""}}),_c('EKInputText',{attrs:{"label":"البلد","name":"countryName","value":_vm.customerDto.countryName,"disabled":""}})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('EKInputText',{attrs:{"label":"رقم الجوال","name":"mobileNumber","value":_vm.customerDto.mobileNumber,"disabled":""}})],1)],1)],1)],1),_c('b-card-footer',[_c('b-row',[_c('b-col',[_c('div',{staticClass:"d-flex"},[_c('b-button',{attrs:{"variant":"outline-primary","to":"./"}},[_vm._v("تراجع")])],1)])],1)],1)],1),[_c('b-row',{staticClass:"mt-5"},[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('h4',{staticStyle:{"font-weight":"900"}},[_vm._v("جدول الطلبات")])]),_c('b-col',{staticStyle:{"display":"flex","justify-content":"flex-end"},attrs:{"cols":"12","md":"6"}},[_c('h4',{staticStyle:{"font-weight":"900"}},[_vm._v(" "+_vm._s("عدد الطلبات: " + _vm.customerDto.orders.length)+" ")])])],1),_c('hr'),_c('EKTable',{attrs:{"items":_vm.customerDto.orders,"columns":_vm.columns},on:{"details":_vm.openOrdersDetails},scopedSlots:_vm._u([{key:"items.orderDate",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(value))+" ")]}},{key:"items.totalPrice",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("numFormat")(value))+" ل.س ")]}},{key:"items.note",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(!!value ? value : "-")+" ")]}},{key:"items.status",fn:function(ref){
var props = ref.props;
return [(props.row.dateFinished == null)?_c('b-badge',{attrs:{"variant":"warning"}},[_vm._v(" قيد الانتظار ")]):(props.row.dateFinished != null)?_c('b-badge',{attrs:{"variant":"success"}},[_vm._v(" منتهي ")]):_vm._e()]}}])})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }