<template>
  <div>
    <b-card no-body class="mb-2">
      <b-card-header class="align-items-center">
        <h4 class="mr-auto mb-0">
          <unicon name="bookmark" width="18" height="18"></unicon>
          معلومات حساب المستخدم
        </h4>
      </b-card-header>
      <b-card-body>
        <b-card-text>
          <b-row>
            <b-col cols="12" md="4">
              <EKInputText
                label="الاسم الكامل"
                name="fullName"
                :value="customerDto.fullName"
                disabled
              />
              <EKInputText
                label="العنوان"
                name="address"
                :value="customerDto.address"
                disabled
              />
              <!-- <EKInputPicker
                  label="تاريخ الميلاد"
                  name="picker"
                  :value="customerDto.dob"
                  disabled
                >
                </EKInputPicker> -->
            </b-col>
            <b-col cols="12" md="4">
              <EKInputText
                label="البريد الإلكتروني"
                name="email"
                :value="customerDto.email"
                disabled
              />
              <EKInputText
                label="البلد"
                name="countryName"
                :value="customerDto.countryName"
                disabled
              />
            </b-col>
            <b-col cols="12" md="4">
              <EKInputText
                label="رقم الجوال"
                name="mobileNumber"
                :value="customerDto.mobileNumber"
                disabled
              />
            </b-col>
          </b-row>
        </b-card-text>
      </b-card-body>
      <b-card-footer>
        <b-row>
          <b-col>
            <div class="d-flex">
              <b-button variant="outline-primary" to="./">تراجع</b-button>
            </div>
          </b-col>
          <!-- <b-col style="display: flex; justify-content: flex-end">
            <b-button variant="outline-danger" @click="deleteCustomer(id)">حذف</b-button>
          </b-col> -->
        </b-row>
      </b-card-footer>
    </b-card>
    <template>
      <b-row class="mt-5">
        <b-col cols="12" md="6"><h4 style="font-weight: 900">جدول الطلبات</h4></b-col>
        <b-col cols="12" md="6" style="display: flex; justify-content: flex-end">
          <h4 style="font-weight: 900">
            {{ "عدد الطلبات: " + customerDto.orders.length }}
          </h4>
        </b-col>
      </b-row>
      <hr />
      <EKTable
        :items="customerDto.orders"
        :columns="columns"
        @details="openOrdersDetails"
      >
        <template slot="items.orderDate" slot-scope="{ value }">
          {{ value | formatDate }}
        </template>
        <template slot="items.totalPrice" slot-scope="{ value }">
          {{ value | numFormat }} ل.س
        </template>
        <template slot="items.note" slot-scope="{ value }">
          {{ !!value ? value : "-" }}
        </template>
        <template slot="items.status" slot-scope="{ props }">
          <b-badge v-if="props.row.dateFinished == null" variant="warning">
            قيد الانتظار
          </b-badge>
          <b-badge v-else-if="props.row.dateFinished != null" variant="success">
            منتهي
          </b-badge>
        </template>
      </EKTable>
    </template>
  </div>
</template>
<style>
.referralCode {
  background-color: #eca05f4d !important;
}
.modal-title {
  margin-left: auto;
  font-size: 20px;
}
</style>
<script>
import EKInputText from "@Ekcore/components/EK-forms/EK-input-text";
//import EKInputPicker from "@Ekcore/components/EK-forms/EK-input-picker";
import EKTable from "@Ekcore/components/EK-table";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    //EKInputPicker,
    EKInputText,
    EKTable,
  },
  props: {
    id: String,
  },
  computed: {
    ...mapState({
      customerDto: (state) => state.customers.customerDto,
    }),
  },
  data: () => ({
    columns: [
      {
        label: "رقم الطلب",
        field: "number",
      },
      {
        label: "تاريخ الطلب",
        field: "orderDate",
      },
      {
        label: "السعر الإجمالي",
        field: "totalPrice",
      },
      {
        label: "الملاحظة",
        field: "note",
      },
      {
        label: "حالة الطلب",
        field: "status",
        sortable: false,
      },
      {
        label: "تفاصيل",
        field: "details",
        sortable: false,
      },
    ],
  }),
  created() {
    this.getCustomerDetails(this.id);
  },
  methods: {
    ...mapActions(["getCustomerDetails"]),
    openOrdersDetails(props) {
      this.$router.push("/admin/orders/" + props.row.id);
    },
  },
};
</script>
